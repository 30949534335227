import React from "react"
import { FadeIn } from "./FadeIn"

export default function YoutubeContainer({ children }) {
  return (
    <FadeIn>
      <div
        css={{
          position: "relative",
          paddingBottom: "56.25%",
          height: 0,
          overflow: "hidden",
          "& iframe": {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          },
        }}
      >
        {children}
      </div>
    </FadeIn>
  )
}
